<template>
  <div class="annex">
    <el-row style="margin-bottom: 15px">
      <el-col :span="18">
        <p style="color: red">
          个人总额：个人预支总金额 - 已报销总金额
          超过设置的额度时，往后每笔预支都需要经过董事长审批，直到个人预支总金额低于个人总额时，才按正常流程审批
        </p>
        <p style="color: red">
          月度限额：个人当月预申请/报销额度超过设置的额度时，往后每笔预申请/报销单都需要经过董事长审批，直到次月
        </p>
        <p style="color: red">
          单笔金额：每笔预申请/报销总金额超过设置的额度时，该预申请/报销单需要经过董事长审批
        </p>
      </el-col>
      <el-col :span="6">
        <div style="padding-left: 62%; padding-top: 8px">
          <el-button type="success" plain @click="changeFn">变更记录信息</el-button>
        </div>
      </el-col>
    </el-row>
    <el-collapse v-model="activeNames">
      <template v-for="(item, index) in ReimbursementList">
        <el-collapse-item :title="item.name" :name="index" :key="index">
          <el-descriptions :column="1" border>
            <el-descriptions-item label-class-name="labelClassName">
              <template v-for="a in item.ruleList">
                <span :key="a.id">
                  <el-switch
                    :active-value="1"
                    :inactive-value="0"
                    v-model="a.bootDisable"
                    style="margin-right: 10px">
                  </el-switch>
                  <span>{{ a.code | dict(limitType) }}</span>
                  <el-input
                    v-model.number="a.amount"
                    :class="a.sign == 1 ? 'color bd_input' : 'bd_input'"
                    @focus="focusFn(a.id)"
                    @blur="blurFn(a.id)"></el-input>
                </span>
              </template>
            </el-descriptions-item>
          </el-descriptions>
        </el-collapse-item>
      </template>
    </el-collapse>

    <el-dialog append-to-body title="变更记录列表" :visible.sync="changeDialog">
      <el-table :data="changeData" border>
        <el-table-column type="index" align="center" label="序号"></el-table-column>
        <el-table-column
          property="createName"
          align="center"
          label="姓名"
          width="200"></el-table-column>
        <el-table-column property="createdDate" align="center" label="日期" width="200">
          <template slot-scope="scope">
            {{ scope.row.createdDate | dateFormat }}
          </template>
        </el-table-column>
        <el-table-column property="module" align="center" label="模块">
          <template slot-scope="scope">
            {{ scope.row.module | dict(rulesType) }}
          </template>
        </el-table-column>
        <!-- <el-table-column property="type" align="center" label="变更类型">
          <template slot-scope="scope">
            {{ scope.row.type | dict(DisposableType) }}
          </template>
        </el-table-column> -->
        <el-table-column property="" align="center" label="操作" width="150">
          <template slot-scope="scope">
            <el-button size="mini" @click="editFn(scope.row)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      title="限额配置-商务费用管理-更变记录"
      :visible.sync="dialogVisible"
      append-to-body
      width="90%"
      v-loading="loading">
      <ChangeDetails :limitType="limitType" :list="list"></ChangeDetails>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    ChangeDetails: () => import('./ChangeDetails.vue'),
  },
  props: {
    source: {
      type: Number,
      default: 0,
    },
    ReimbursementModule: {
      type: Object,
      default: function () {
        return {}
      },
    },
    limitType: {
      type: Array,
      default: function () {
        return []
      },
    },
    rulesType: {
      type: Array,
      default: function () {
        return []
      },
    },
    ReimbursementList: {
      type: Array,
      default: function () {
        return []
      },
    },
    usedReimbursementList: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      changeDialog: false,
      dialogVisible: false,
      disabled: false,
      loading: true,
      changeData: [],
      list: [],
      activeNames: [0, 1, 2, 3, 4],
    }
  },
  computed: {
    ...mapState({
      projectInformation: state => state.project.projectInformation,
    }),
  },
  filters: {
    dateFormat(time3) {
      //将时间戳格式转换成年月日时分秒
      var date = new Date(time3)
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '

      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      var strDate = Y + M + D + h + m + s
      return strDate
    },
  },
  watch: {
    // ReimbursementList: {
    //   deep: true,
    //   immediate: true,
    //   handler: function(newVal, oldVal) {
    //     if (this.source == 1) {
    //       let newobj = {}; //去重
    //       this.ExpenseList = newVal.reduce((preVal, curVal) => {
    //         newobj[curVal.type] ? "" : (newobj[curVal.type] = preVal.push(curVal));
    //         return preVal;
    //       }, []);
    //     }
    //   },
    // },
  },
  created() {},
  methods: {
    editFn(row) {
      this.loading = true
      if (this.source == 0) {
        this.$api.limitConfiguration
          .selectLogDetailById(row.id)
          .then(res => {
            this.list = []
            this.list = res.data
            this.loading = false
          })
          .catch(err => {
            console.log(err)
            this.loading = false
          })
      }
      //    else if (this.source == 1) {
      //     this.$api.project
      //       .projectRuleSelectLogDetailById(row.id)
      //       .then((res) => {
      //         this.list = res.data;
      //         this.loading = false;
      //         if (row.type) {
      //           this.isType = row.type;
      //           this.loading = false;
      //         }
      //       })
      //       .catch((err) => {
      //         console.log(err);
      //       });
      //   }
      this.dialogVisible = true
    },
    focusFn(id) {
      this.ReimbursementList.forEach(item => {
        item.ruleList.forEach(v => {
          if (v.id == id) {
            v.amount = String(v.amount).replace(/,/g, '')
          }
        })
      })
    },
    blurFn(id) {
      let num = 0
      this.usedReimbursementList.forEach(item => {
        item.ruleList.forEach(v => {
          if (v.id == id) {
            num = String(v.amount).replace(/,/g, '')
          }
        })
      })
      this.ReimbursementList.forEach(item => {
        item.ruleList.forEach(v => {
          if (v.id == id) {
            if (num == v.amount) {
              v.sign = 0
            } else {
              v.sign = 1
            }
            v.amount = Number(v.amount).toLocaleString()
          }
        })
      })
    },
    changeFn() {
      if (this.source == 0) {
        this.$api.limitConfiguration
          .limitConfigurationLogList(this.ReimbursementModule)
          .then(res => {
            this.changeData = res.data
          })
          .catch(err => {
            console.log(err)
          })
      }
      //   else if (this.source == 1) {
      //     this.$api.project
      //       .projectRuleSelectRulelogList(this.ReimbursementModule)
      //       .then((res) => {
      //         this.changeData = res.data;
      //       })
      //       .catch((err) => {});
      //   }
      this.changeDialog = true
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.annex {
  /deep/.el-collapse-item__header {
    font-weight: 900;
    color: #000;
    font-size: 18px;
  }
  /deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
    padding: 5px;
  }

  .color {
    background-color: #f5e9057d;
  }
  .bd_input {
    border: 1px solid #ccc;
    height: 40px;
    width: 150px;
    margin: 0px 20px 0px 10px;
    border-radius: 5%;
    text-align: center;
  }
  // 改变input框背景颜色
  /deep/.el-input__inner {
    background-color: transparent !important;
    border: 0 !important;
  }
  /* 利用穿透，设置input边框隐藏 */
  .bd_input >>> .el-input__inner {
    border: 0;
  }
  /deep/.labelClassName {
    width: 20px;
  }
}
</style>
